import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "utils/device";
import { CL } from "components/Typography";
import { colours } from "utils";
import MarketingLink from "../MarketingLink";
import ArticleScroll from "./ArticleScroll";
import { Article } from "@/types/article/Article";

type TheBrightPostContentProps = {
  articles: Article[];
};

const TheBrightPostContent: FunctionComponent<TheBrightPostContentProps> = (
  props
) => {
  return (
    <S.Section>
      <S.ContentContainer>
        <S.CopyContainer>
          <S.Title>
            The AllBright <br />
            <S.RoxboroughFontWrap>
              po<i>s</i>t
            </S.RoxboroughFontWrap>
          </S.Title>
          <CL weight="500">
            Uncover the latest news for women, discover insights from industry
            experts on leadership and keep in the loop with the trends in the
            world of work. The must-have companion for any working woman.
          </CL>
          <MarketingLink
            href="/edit"
            colour={colours.marketingYellow}
            animationColour={colours.marketingNavy}
            linkText="Read articles"
          />
        </S.CopyContainer>
        <ArticleScroll articles={props.articles} />
      </S.ContentContainer>
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section`
  width: 100%;
`;

S.Title = styled.p`
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.3rem;
  line-height: 4rem;

  @media (min-width: ${Device.mobile}) {
  }

  @media (min-width: ${Device.tablet}) {
    width: 60%;
    line-height: 6rem;
  }
`;

S.RoxboroughFontWrap = styled.span`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
  letter-spacing: 1.5rem;
  font-size: 6rem;

  @media (min-width: ${Device.tablet}) {
    letter-spacing: 2rem;
    font-size: 8rem;
  }
`;

S.ContentContainer = styled.div`
  padding: 5% 0 10% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${Device.tablet}) {
    padding: 0 0 5% 0;
  }
`;

S.CopyContainer = styled.div`
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: ${Device.tablet}) {
    width: 70%;
    gap: 20px;
  }

  @media (min-width: ${Device.desktop}) {
    padding: 5% 10% 0 10%;
    width: 50%;
  }
`;

export default TheBrightPostContent;

import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "utils/device";
import { CL } from "components/Typography";
import { colours } from "utils";
import MarketingLink from "../MarketingLink";

const RedefiningAmbitionContent: FunctionComponent = () => {
  return (
    <S.Section>
      <S.ContentContainer>
        <S.Title>
          Redefining
          <br />
          <S.RoxboroughTextWrap>ambition,</S.RoxboroughTextWrap>
          <br />
          transforming
          <br />
          <S.RoxboroughTextWrap>
            <i>businesses</i>
          </S.RoxboroughTextWrap>
        </S.Title>
        <S.CopyContainer>
          <CL weight="700">
            As the world’s largest collective of women in business, we enable
            ambition in all its forms, supporting women at every stage of their
            development.
          </CL>
          <CL weight="700">
            Together, we co-create a workplace where diversity thrives,
            creativity soars, and success knows no gender boundaries.
          </CL>
          <CL>
            Ambition, for many women, is often limited by social norms and
            gender-based stereotypes. It doesn’t have to be that way.
          </CL>
          <CL>
            Now is the time to own your ambition, prioritise your personal
            development and achieve professional success. Let’s get started,
            together.
          </CL>
        </S.CopyContainer>
        <S.LinksContainer>
          <MarketingLink
            href="/business"
            colour={colours.shell}
            textColour={colours.shell}
            animationColour={colours.marketingOrange}
            linkText="For Business"
          />
          <MarketingLink
            href="/individuals"
            colour={colours.shell}
            textColour={colours.shell}
            animationColour={colours.marketingOrange}
            linkText="For Individuals"
          />
        </S.LinksContainer>
      </S.ContentContainer>
      <S.Image />
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section`
  width: 100%;
  padding: 10%;
  color: ${colours.shell};
  background-image: url("/images/marketing/strategy-meets-serendipity-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

  @media (min-width: ${Device.desktop}) {
    background-image: url("/images/marketing/strategy-meets-serendipity-deskop.jpg");
    display: flex;
    flex-direction: row-reverse;
  }
`;

S.ContentContainer = styled.div`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "title"
    "links"
    "copy";
  gap: 30px;

  @media (min-width: ${Device.desktop}) {
    width: 45%;
    grid-template-areas:
      "title"
      "copy"
      "links";
    gap: 40px;
  }
`;

S.Title = styled.h3`
  grid-area: title;
  position: relative;
  font-weight: 700;
  font-size: 3rem;
  line-height: 2.6rem;

  @media (min-width: ${Device.mobile}) {
    font-size: 5rem;
    line-height: 4.5rem;
  }

  @media (min-width: ${Device.tablet}) {
    line-height: 6rem;
    line-height: 4.5rem;
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 8rem;
    line-height: 6.5rem;
  }
`;

S.RoxboroughTextWrap = styled.span`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
`;

S.LinksContainer = styled.div`
  grid-area: links;
  display: flex;
  gap: 40px;
`;

S.Image = styled.div`
  width: 100%;
  aspect-ratio: 1/1.2;

  @supports not (aspect-ratio: 1/1.2) {
    &:before {
      float: left;
      padding-top: 120%;
      content: "";
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }

  @media (min-width: ${Device.mobile}) {
    aspect-ratio: 1/1.1;

    @supports not (aspect-ratio: 1/1.1) {
      &:before {
        float: left;
        padding-top: 110%;
        content: "";
      }
      &:after {
        display: block;
        content: "";
        clear: both;
      }
    }
  }
`;

S.CopyContainer = styled.div`
  grid-area: copy;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 10px 0 0 0;

  @media (min-width: ${Device.mobile}) {
    padding: 20px 10% 20px 0;
  }

  @media (min-width: ${Device.tablet}) {
    padding: 20px 25% 20px 0;
  }

  @media (min-width: ${Device.desktop}) {
    padding: 20px 15% 20px 0;
  }
`;

export default RedefiningAmbitionContent;

import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "utils/device";
import { colours } from "utils";
import Image from "next/image";
import { uuid } from "utils";

const SEEN_IN_IMGS = [
  "/images/as-seen-in/sky.png",
  "/images/as-seen-in/evening_standard.png",
  "/images/as-seen-in/bloomberg.png",
  "/images/as-seen-in/today.png",
  "/images/as-seen-in/tatler.png",
  "/images/as-seen-in/nbc.png",
  "/images/as-seen-in/forbes.png",
  "/images/as-seen-in/harpers_bazaar.png",
  "/images/as-seen-in/sunday_times.png",
  "/images/as-seen-in/glamour.png",
];

const AsSeenInContent: FunctionComponent = () => {
  return (
    <S.Section>
      <S.Title>As seen in</S.Title>
      <S.LogosContainer>
        {SEEN_IN_IMGS.map((image) => (
          <S.ImageContainer key={uuid()}>
            <Image
              src={image}
              alt={"IMAGE"}
              layout="fill"
              objectFit="contain"
            />
          </S.ImageContainer>
        ))}
      </S.LogosContainer>
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section`
  width: 100%;
  background: ${colours.marketingBeige};
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${Device.desktop}) {
    padding: 5% 10%;
  }
`;

S.Title = styled.h3`
  font-weight: 800;
  font-size: 4rem;
  position: relative;
  line-height: 3.2rem;

  @media (min-width: ${Device.mobile}) {
    font-size: 5rem;
    line-height: 3.4rem;
  }

  @media (min-width: ${Device.tablet}) {
    line-height: 4rem;
  }
`;

S.LogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 50px;

  @media (min-width: ${Device.mobile}) {
    padding: 5% 0 0 0;
  }
`;

S.ImageContainer = styled.div`
  position: relative;
  height: 100px;
  width: 100px;

  @media (min-width: ${Device.tablet}) {
    height: 150px;
    width: 150px;
  }
`;

export default AsSeenInContent;

import React, { FunctionComponent } from "react";
import styled from "styled-components";

import Player from "@vimeo/player";
import { AllBrightVector } from "components/Icons";
import { Device } from "utils/device";
import { CXXL, CL, CS } from "components/Typography";
import { colours } from "utils";
import MarketingLink from "../MarketingLink";
import Image from "next/image";
import HeaderSlideContent from "@/types/marketing/HeaderSlideContent";

type StyleProps = {
  backgroundColour?: string;
  copyPosition?: string;
  longTitle?: boolean;
  subtitleColour?: string;
};

interface HeaderSlideProps extends HeaderSlideContent {}

const HeaderSlide: FunctionComponent<HeaderSlideProps> = (props) => {
  const playerRef = React.useRef(null);
  const playerContainerRef = React.useRef(null);
  const longTitle = props.title.length > 50;
  React.useEffect(() => {
    destroyPlayer();
    createPlayer();
  }, []);

  function destroyPlayer() {
    if (!playerRef.current) return;
    playerRef.current.destroy();
  }

  function createPlayer() {
    if (!playerContainerRef.current) return;

    playerRef.current = new Player(playerContainerRef.current, {
      url: props.vimeoUrl,
      autoplay: true,
      muted: true,
      loop: true,
      playsinline: true,
      transparent: false,
    });
  }

  return (
    <S.Section {...props}>
      <S.ContentContainer {...props}>
        {props.subtitleFirst ? (
          <CL color={colours[props.subtitleColour]}>
            {props.subtitleFirst}{" "}
            <S.RoxboroughFontWrap>{props.subtitleMiddle}</S.RoxboroughFontWrap>{" "}
            {props.subtitleLast}
          </CL>
        ) : (
          <AllBrightVector colour={props.subtitleColour} height="30" />
        )}
        <S.Title
          roxborough
          longTitle={longTitle}
          color={colours[props.fontColour]}
          weight="100"
          textAlign="center"
        >
          {props.title}
        </S.Title>
        <S.Copy
          color={colours[props.fontColour]}
          weight="700"
          textAlign="center"
        >
          {props.copy}
        </S.Copy>

        {/* Previous dynamic link */}
        {/* <MarketingLink
          href={props.linkUrl}
          colour={colours[props.linkLineColour]}
          textColour={colours[props.fontColour]}
          animationColour={colours.marketingBlue}
          linkText={props.linkText}
        /> */}

        {/* TODO: temporarily hardcoding these until the client can be updated */}
        <S.LinkContainer>
          <MarketingLink
            href={"/business"}
            colour={colours[props.linkLineColour]}
            textColour={colours[props.fontColour]}
            animationColour={colours.charcoal}
            linkText={"For Business"}
          />
          <MarketingLink
            href={"/individuals"}
            colour={colours[props.linkLineColour]}
            textColour={colours[props.fontColour]}
            animationColour={colours.charcoal}
            linkText={"For Individuals"}
          />
        </S.LinkContainer>
      </S.ContentContainer>
      {props.vimeoUrl ? (
        <S.VideoContainer ref={playerContainerRef} title={props.title} />
      ) : (
        <S.ImageContainer>
          <Image
            src={props.image.url}
            alt={props.image.description || props.image.title}
            layout="fill"
            objectFit="cover"
          />
        </S.ImageContainer>
      )}
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.article<StyleProps>`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${Device.tablet}) {
    flex-direction: ${(p) =>
      p.copyPosition === "left" ? "row" : "row-reverse"};
    aspect-ratio: 3/1;

    @supports not (aspect-ratio: 3/1) {
      &::before {
        float: left;
        padding-top: 33%;
        content: "";
      }
      &::after {
        display: block;
        content: "";
        clear: both;
      }
    }
  }
`;

S.ContentContainer = styled.div<StyleProps>`
  max-height: 400px;
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: white;
  aspect-ratio: 1;

  ${(p) => {
    switch (p.backgroundColour) {
      case "blue pattern":
        return `background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(0, 8, 53, 0.00) 38.71%, #000835 59.04%, #000835 100%), url('/images/marketing/blue-tessellation.png');`;
      case "green pattern":
        return `background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(0, 8, 53, 0.00) 38.71%, #000835 59.04%, #000835 100%), url('/images/marketing/blue-tessellation.png');`;
      default:
        return `background: ${colours[p.backgroundColour]};`;
    }
  }}

  @media(min-width: ${Device.tablet}) {
    max-height: 900px;
    width: 40%;
    padding: 5%;
    gap: 10px;
  }

  @media (min-width: ${Device.desktop}) {
    gap: 20px;
  }
`;

S.RoxboroughFontWrap = styled.span`
  font-family:
    Roxborough CF,
    sans-serif;
  font-style: italic;
`;

S.Title = styled(CXXL)<StyleProps>`
  font-size: ${(p) => (p.longTitle ? "2" : "2.3")}rem;

  @media (min-width: ${Device.mobile}) {
    font-size: ${(p) => (p.longTitle ? "3" : "3.9")}rem;
  }

  @media (min-width: ${Device.tablet}) {
    font-size: ${(p) => (p.longTitle ? "1.5" : "2")}rem;
  }

  @media (min-width: ${Device.desktop}) {
    font-size: ${(p) => (p.longTitle ? "2" : "3")}rem;
  }
`;

S.Copy = styled(CS)`
  @media (min-width: ${Device.desktop}) {
    font-size: 1.5rem;
  }
`;

S.ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 16/9;

  @supports not (aspect-ratio: 16/9) {
    &::before {
      float: left;
      padding-top: 56%;
      content: "";
    }
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }

  @media (min-width: ${Device.tablet}) {
    width: 60%;
  }
`;

S.VideoContainer = styled.div`
  position: relative;
  aspect-ratio: 16/9;

  @supports not (aspect-ratio: 16/9) {
    &::before {
      float: left;
      padding-top: 56%;
      content: "";
    }
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${Device.tablet}) {
    width: 60%;
  }
`;

S.Video = styled.iframe`
  border: none;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

S.LinkContainer = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 50px;

  @media (min-width: ${Device.tablet}) {
    padding-top: 20px;
  }
  @media (min-width: ${Device.desktop}) {
    padding-top: 40px;
  }
`;

export default HeaderSlide;

import React from "react";
import styled from "styled-components";

import { LoadingVector } from "@/components/Icons";
import { CL } from "@/components/Typography";

// The below array will be used for onboarding only

// const onboardingTexts = [
//   "Preparing mentors",
//   "Selecting appropriate courses",
//   "Scheduling events",
// ];

const defaultTexts = [
  "Our Mentorship programme allows you to select your own mentor from our pool of experts.",
  "You can add any AllBright member just by tapping their business card and choosing 'connect'.",
  "The latest events or courses you are attending will appear on your home screen.",
  "The recommendations on your home screen are specially curated just for you.",
  "You can curate your own learning by saving courses to come back to later.",
  "We now encourage you to join in with Question of the Week in our Conversation.",
];

const RxLoadingScreen = () => {
  const getRandomIndex = (current) => {
    const newIndex = Math.floor(Math.random() * defaultTexts.length);
    return newIndex === current ? getRandomIndex(currentIndex) : newIndex;
  };

  const [currentIndex, setCurrentIndex] = React.useState(getRandomIndex(-1));

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(getRandomIndex(currentIndex));
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  return (
    <S.Page>
      <LoadingVector dataCy="loading-vector" />
      <S.LoadingTextFade weight="500" data-cy="loading-text">
        {defaultTexts[currentIndex]}
      </S.LoadingTextFade>

      {/* The below element will be used with the onboarding text for an ellipsis animation */}

      {/* <S.LoadingTextEllipsis weight='500'>
        Fetching your AllBright sisters&nbsp;
      </S.LoadingTextEllipsis> */}
    </S.Page>
  );
};

const S = () => {};

S.Page = styled.div`
  width: 100%;
  min-height: 600px;
  background: shell;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

S.LoadingTextEllipsis = styled(CL)`
  margin: 0 0 0 -20px;

  &:after {
    white-space: nowrap;
    position: absolute;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 1.5s infinite;
    animation: ellipsis steps(4, end) 1.5s infinite;
    content: ". . .";
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 30px;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 30px;
    }
  }
`;

S.LoadingTextFade = styled(CL)`
  text-align: center;
  overflow: hidden;
  padding: 0 10%;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: showText 8s infinite;
  animation: showText 8s infinite;
  opacity: 0;

  @keyframes showText {
    40% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes showText {
    40% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default RxLoadingScreen;

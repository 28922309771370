import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Image from "next/image";

import { Device } from "utils/device";
import { CL } from "components/Typography";
import { colours } from "utils";
import MarketingLink from "../MarketingLink";

const InvestContent: FunctionComponent = () => {
  return (
    <S.Section>
      <S.TitleContainer>
        <S.Title>
          Invest in{" "}
          <S.RoxboroughTextWrap>
            <i>your</i> ambition
          </S.RoxboroughTextWrap>
          <br />
        </S.Title>
        <S.Subtitle>
          <S.RoxboroughTextWrap>— the time is now</S.RoxboroughTextWrap>
        </S.Subtitle>
      </S.TitleContainer>
      <S.ContentContainer>
        <S.ContentBlock>
          <S.ContentImageContainer>
            <Image
              src="/images/marketing/Frame 1709.jpg"
              alt="Two women hugging"
              layout="fill"
              objectFit="contain"
            />
          </S.ContentImageContainer>
          <S.ContentTitle>Connect</S.ContentTitle>
          <CL weight="700">
            AllBright membership welcomes you into a vibrant community of women
            from diverse backgrounds, sectors and roles.
          </CL>
          <CL>
            Connect with industry leaders, mentors, and fellow members, creating
            lasting relationships that amplify your professional journey, gain
            influence and exposure to career-changing opportunities.
          </CL>
        </S.ContentBlock>
        <S.ContentBlock>
          <S.ContentImageContainer>
            <Image
              src="/images/marketing/Frame 1708.jpg"
              alt="The AllBright platform is available on laptop and mobile"
              layout="fill"
              objectFit="contain"
            />
          </S.ContentImageContainer>
          <S.ContentTitle>Learn</S.ContentTitle>
          <CL weight="700">
            In a changing world, learning is the constant that future-proofs
            your career and ensures your success.
          </CL>
          <CL>
            AllBright membership offers tailored coaching, masterclasses,
            workshops, and resources to boost your career. Tap into the power of
            learning to gain the competitive edge, expand your expertise, and
            achieve new career heights.
          </CL>
        </S.ContentBlock>
        <S.ContentBlock>
          <S.ContentImageContainer>
            <Image
              src="/images/marketing/Frame 1710.png"
              alt='A woman replies to a text message that reads, "Hi Janes, it is great to connect! I specialise in helping clients grow their digital audiences, it would be great to grab a coffee and talk about the latest and greatest in e-commerce."'
              layout="fill"
              objectFit="contain"
            />
          </S.ContentImageContainer>
          <S.ContentTitle>Inspire</S.ContentTitle>
          <CL weight="700">
            Access year-long programming online, or at our stylish restaurants
            and spaces designed to elevate your happiness, support your
            ambition, and open your mind to new possibilities.
          </CL>
          <CL>
            Surround yourself with influential leaders, mentors and a diverse
            network of peers, as you’re inspired to achieve your boldest
            aspirations.
          </CL>
        </S.ContentBlock>
      </S.ContentContainer>
      <S.LinksContainer>
        <MarketingLink
          href="/business"
          colour={colours.charcoal}
          textColour={colours.charcoal}
          animationColour={colours.marketingOrange}
          linkText="For Business"
        />
        <MarketingLink
          href="/individuals"
          colour={colours.charcoal}
          textColour={colours.charcoal}
          animationColour={colours.marketingOrange}
          linkText="For Individuals"
        />
      </S.LinksContainer>
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section`
  width: 100%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${colours.shell};

  @media (min-width: ${Device.tablet}) {
    gap: 40px;
  }

  @media (min-width: ${Device.desktop}) {
    gap: 80px;
  }
`;

S.TitleContainer = styled.div`
  grid-area: title;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: ${Device.mobile}) {
    gap: 20px;
  }

  @media (min-width: ${Device.tablet}) {
    gap: 30px;
  }

  @media (min-width: ${Device.desktop}) {
    gap: 40px;
  }
`;

S.Title = styled.h3`
  position: relative;
  font-weight: 700;
  font-size: 3rem;
  line-height: 2.6rem;

  @media (min-width: ${Device.mobile}) {
    font-size: 5rem;
    line-height: 4.5rem;
  }

  @media (min-width: ${Device.tablet}) {
    line-height: 6rem;
    line-height: 4.5rem;
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 8rem;
    line-height: 6.5rem;
  }
`;

S.RoxboroughTextWrap = styled.span`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
`;

S.Subtitle = styled.p`
  font-size: 2rem;

  @media (min-width: ${Device.mobile}) {
    font-size: 3rem;
  }

  @media (min-width: ${Device.tablet}) {
    font-size: 4rem;
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 6rem;
  }
`;

S.ContentContainer = styled.div`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${Device.tablet}) {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
`;

S.ContentBlock = styled.article`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${Device.desktop}) {
    gap: 30px;
  }
`;

S.ContentImageContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;

  @supports not (aspect-ratio: 1) {
    &:before {
      float: left;
      padding-top: 100%;
      content: "";
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
`;

S.ContentTitle = styled.h4`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
  font-size: 3rem;

  @media (min-width: ${Device.mobile}) {
    font-size: 4rem;
  }

  @media (min-width: ${Device.tablet}) {
    line-height: 6rem;
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 6rem;
  }
`;

S.ContentCopy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 10px 0 20px 0;

  @media (min-width: ${Device.mobile}) {
    padding: 20px 10% 20px 0;
  }

  @media (min-width: ${Device.tablet}) {
    padding: 20px 25% 20px 0;
  }

  @media (min-width: ${Device.desktop}) {
    padding: 20px 15% 20px 0;
  }
`;

S.LinksContainer = styled.div`
  grid-area: links;
  padding: 0 0 10px 0;
  display: flex;
  gap: 40px;
`;

export default InvestContent;

import colours from "./colours";

export const quoteInTodaysWorld = {
  author: "Viviane Paxinos, AllBright CEO",
  background: colours.marketingNavy,
  fontColour: colours.shell,
  largeText: true,
  hrefOne: "/business",
  linkTextOne: "For Business",
  hrefTwo: "/individuals",
  linkTextTwo: "For Individuals",
  animationColour: colours.marketingOrange,
  paragraphs: [
    "In today’s world, ambition is often misunderstood, and women can find themselves pulling away from it, fearing its implications or the societal expectations attached to it.",
    "But, we are here to change that narrative.",
  ],
  roxborough: true,
  source: "on redefining ambition",
};

export const quoteWeBelieve = {
  author: "Viviane Paxinos, AllBright CEO",
  background: colours.marketingOrange,
  linkUrl: "/memberships",
  linkText: "Join AllBright",
  paragraphs: [
    "We believe that ambition, when harnessed authentically and consciously, is a powerful force for good. It’s about setting your own path, defining success on your terms, and investing in that ambition to future-proof yourself in an ever-evolving business landscape.",
    "Join us on this journey, as we celebrate the resilience, tenacity, and brilliance of women who are unapologetically ambitious.",
    "Together, let’s reclaim ambition, shape our own destinies, and make a profound impact not just in our lives, but in the world of business and beyond.",
  ],
  source: "on redefining ambition",
};

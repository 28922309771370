export default [
  {
    title: "Boss",
    imgSrc: "/images/brand-partners/brand-BOSS.jpg",
  },
  {
    title: "Smart Works",
    imgSrc: "/images/brand-partners/brand-SMARTWORKS.jpg",
  },
  {
    title: "Chelsea WFC",
    imgSrc: "/images/brand-partners/brand-CHELSEA.jpg",
  },
  {
    title: "HSBC",
    imgSrc: "/images/brand-partners/brand-HSBC.jpg",
  },
  {
    title: "LG",
    imgSrc: "/images/brand-partners/brand-LG.jpg",
  },
  {
    title: "Theory",
    imgSrc: "/images/brand-partners/brand-theory.jpg",
  },
  {
    title: "Boden",
    imgSrc: "/images/brand-partners/brand-boden.png",
  },
  {
    title: "Daye",
    imgSrc: "/images/brand-partners/brand-daye.png",
  },
  {
    title: "No7",
    imgSrc: "/images/brand-partners/brand-no7.png",
  },
  {
    title: "Pandora",
    imgSrc: "/images/brand-partners/brand-pandora.png",
  },
  {
    title: "Rituals",
    imgSrc: "/images/brand-partners/brand-rituals.png",
  },
  {
    title: "Sipsmith",
    imgSrc: "/images/brand-partners/brand-sipsmith.png",
  },
  {
    title: "Toni&Guy",
    imgSrc: "/images/brand-partners/brand-toni&guy.png",
  },
  {
    title: "Veuve Clicquot",
    imgSrc: "/images/brand-partners/brand-veuveclicquot.png",
  },
  {
    title: "BMW",
    imgSrc: "/images/brand-partners/brand-BMW.jpg",
  },
  {
    title: "Pimentae",
    imgSrc: "/images/brand-partners/brand-pimentae.png",
  },
];

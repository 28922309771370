import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "utils/device";
import { CL } from "components/Typography";
import BrandScroll from "./BrandScroll";
import brandPartners from "utils/brandPartners";

const ProfitabilityContent: FunctionComponent = () => {
  return (
    <S.Section>
      <S.ContentContainer>
        <S.Title>
          Profitability{" "}
          <S.RoxboroughFontWrap>
            <i>meets</i> purpose
          </S.RoxboroughFontWrap>
        </S.Title>
        <S.CopyContainer>
          <CL weight="500">
            AllBright advocates for brands with ambitious values, who strive for
            excellence in people, purpose and profit.
          </CL>
        </S.CopyContainer>
      </S.ContentContainer>
      <BrandScroll brands={brandPartners} />
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section`
  width: 100%;
`;

S.Title = styled.h3`
  font-weight: 800;
  font-size: 4rem;
  position: relative;
  line-height: 3.2rem;

  @media (min-width: ${Device.mobile}) {
    font-size: 5rem;
    line-height: 3.4rem;
  }

  @media (min-width: ${Device.tablet}) {
    line-height: 4rem;
  }
`;

S.RoxboroughFontWrap = styled.span`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
`;

S.ContentContainer = styled.div`
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media (min-width: ${Device.tablet}) {
    padding: 5% 10%;
    gap: 30px;
    align-items: center;
  }
`;

S.CopyContainer = styled.div`
  @media (min-width: ${Device.tablet}) {
    text-align: center;
    width: 60%;
  }

  @media (min-width: ${Device.desktop}) {
    width: 40%;
  }
`;

S.ImageContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 4/2;
`;

export default ProfitabilityContent;

import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "utils/device";
import { colours } from "utils";

type StyleProps = {
  image?: string;
  isHorizontal?: boolean;
  topRow?: boolean;
};

type BrandScrollProps = {
  brands: {
    title: string;
    imgSrc: string;
  }[];
};

const BrandScroll: FunctionComponent<BrandScrollProps> = (props) => {
  const evenColumns = props.brands.filter((_, index) => index % 2 === 0);
  const oddColumns = props.brands.filter((_, index) => index % 2 !== 0);

  return (
    <S.ScrollContainer>
      <S.Row topRow key="">
        {evenColumns.map((brand, index) => (
          <S.BrandCard
            isHorizontal={!(index % 2 === 0)}
            image={brand.imgSrc}
            key={brand.title}
          >
            <S.TitleContainer>
              <S.Title>{brand.title}</S.Title>
            </S.TitleContainer>
          </S.BrandCard>
        ))}
      </S.Row>
      <S.Row>
        {oddColumns.map((brand, index) => (
          <S.BrandCard
            isHorizontal={index % 2 === 0}
            image={brand.imgSrc}
            key={brand.title}
          >
            <S.TitleContainer>
              <S.Title>{brand.title}</S.Title>
            </S.TitleContainer>
          </S.BrandCard>
        ))}
      </S.Row>
    </S.ScrollContainer>
  );
};

const S = () => {};

S.ScrollContainer = styled.ul`
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  white-space: nowrap;

  @media (min-width: ${Device.desktop}) {
    max-width: 1440px;
  }
`;

S.Row = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  margin: -15px 0 0 0;

  ${(p) =>
    p.topRow &&
    `
    padding: 0 0 0 20px;
  `}

  @media(min-width: ${Device.tablet}) {
    margin: -10px 0 0 0;
  }
`;

S.BrandCard = styled.li<StyleProps>`
  position: relative;
  margin: 0 0 0 5px;
  flex-shrink: 0;
  list-style-type: none;
  height: ${(p) => (p.isHorizontal ? "140" : "180")}px;
  width: ${(p) => (p.isHorizontal ? "180" : "140")}px;
  background-image: url(${(p) => p.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s;

  @media (min-width: ${Device.tablet}) {
    margin: 0 0 0 10px;
    height: ${(p) => (p.isHorizontal ? "180" : "220")}px;
    width: ${(p) => (p.isHorizontal ? "220" : "180")}px;
  }
`;

S.TitleContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
`;

S.Title = styled.p`
  position: relative;
  width: 100%;
  text-align: center;
  font-family:
    Roxborough CF,
    sans-serif;
  font-size: 2rem;
  font-weight: 100;
  color: ${colours.shell};

  &::after {
    background: ${colours.marketingYellow};
    bottom: -10px;
    content: "";
    display: inline-block;
    height: 2px;
    position: absolute;
    width: 50%;
    right: 25%;
  }

  @media (min-width: ${Device.tablet}) {
    font-size: 3rem;
  }
`;

export default BrandScroll;

import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "utils/device";
import { CM, CS } from "components/Typography";
import { colours } from "utils";
import MarketingLink from "../MarketingLink";

const MeetTheCeoContent: FunctionComponent = () => {
  return (
    <S.Section id="meet-our-ceo">
      <S.FloatingCopyContainer>
        <S.Title>
          Meet the{" "}
          <S.RoxboroughFontWrap>
            <br />
            CEO
          </S.RoxboroughFontWrap>
        </S.Title>
        <CS weight="500">
          At AllBright, we stand at the intersection of ambition and
          empowerment, a place where women from all walks of life can redefine
          ambition on their own terms.
        </CS>
        <CS weight="500">
          We take immense pride in the physical and virtual spaces we've
          created, spaces where women can connect, upskill, inspire one another
          and be inspired by globally renowned experts.
        </CS>
        <CM>
          <b>Viviane Paxinos, AllBright CEO |</b> <i>on redefining ambition</i>
        </CM>
        <S.LinksContainer>
          <MarketingLink
            href="/business"
            colour={colours.shell}
            textColour={colours.shell}
            animationColour={colours.marketingYellow}
            linkText="For Business"
          />
          <MarketingLink
            href="/individuals"
            colour={colours.shell}
            textColour={colours.shell}
            animationColour={colours.marketingYellow}
            linkText="For Individuals"
          />
        </S.LinksContainer>
      </S.FloatingCopyContainer>
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section`
  position: relative;
  width: 100%;
  padding: 10% 0;
  background-image: url("/images/Viv.jpeg");
  background-size: 120%;
  background-position: 0 20%;
  background-repeat: no-repeat;

  @media (min-width: ${Device.desktop}) {
    background-size: 110%;
  }
`;

S.Title = styled.h3`
  font-weight: 800;
  font-size: 3rem;
  position: relative;
  line-height: 3.2rem;

  @media (min-width: ${Device.mobile}) {
    font-size: 5rem;
    line-height: 3.8rem;
  }

  @media (min-width: ${Device.tablet}) {
    font-size: 6rem;
    line-height: 4.8rem;
  }
`;

S.RoxboroughFontWrap = styled.span`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
`;

S.FloatingCopyContainer = styled.article`
  width: 50%;
  border-radius: 0 5px 5px 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  color: ${colours.shell};

  @media (min-width: ${Device.desktop}) {
    width: 40%;
  }
`;

S.LinksContainer = styled.div`
  grid-area: links;
  padding: 0 0 10px 0;
  display: flex;
  gap: 40px;

  @media (min-width: ${Device.mobile}) {
    padding-top: 2rem;
  }
`;

export default MeetTheCeoContent;

import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { colours } from "../../../utils";
import { uuid } from "../../../utils";
import RxLoadingScreen from "./RxLoadingScreen";

type StyleProps = {
  carouselWidth?: number;
  contentDirection?: string;
  transform?: number;
  transformPercentage?: number;
  transition?: boolean;
  left?: boolean;
  right?: boolean;
};

type RxHeaderCarouselProps = {
  children: React.ReactNode[];
};

const RxHeaderCarousel: FunctionComponent<RxHeaderCarouselProps> = (props) => {
  if (!props.children || props.children.length < 1) return <RxLoadingScreen />;
  const carouselWidth = props.children.length * 100;
  const transformPercentage = 100 / props.children.length;
  const [page, setPage] = React.useState(0);
  const [contentDirection, setContentDirection] = React.useState("flex-start");
  const [transform, setTransform] = React.useState(0);
  const [newSlides, setNewSlides] = React.useState([...props.children]);
  const [transition, setTransition] = React.useState(true);

  const handleArrowClick = (direction) => {
    const newArray = [...newSlides];

    if (direction === "right") {
      if (page === 1) {
        newArray.unshift(newArray.pop());
        setNewSlides(newArray);
      }
      setContentDirection("flex-start");
      setTransform(-transformPercentage);
      setPage(-1);
    }

    if (direction === "left") {
      if (page === -1) {
        newArray.push(newArray.shift());
        setNewSlides(newArray);
      }
      setPage(1);
      setContentDirection("flex-end");
      setTransform(transformPercentage);
    }
  };

  const handleTransitionEnd = (element) => {
    if (element.id !== "slider") return;
    const newArray = [...newSlides];
    if (page === -1) {
      newArray.push(newArray.shift());
    } else if (page === 1) {
      newArray.unshift(newArray.pop());
    }
    setNewSlides(newArray);
    setTransition(false);
    setTransform(0);
    setTimeout(() => {
      setTransition(true);
    });
  };

  return (
    <S.Section data-cy="header-carousel">
      <S.Carousel contentDirection={contentDirection}>
        <S.Slider
          transform={transform}
          transition={transition}
          carouselWidth={carouselWidth}
          onTransitionEnd={(e) => handleTransitionEnd(e.target)}
          id="slider"
        >
          <S.SliderSection transformPercentage={transformPercentage}>
            {newSlides.map((slide, index) => (
              <S.Slide data-cy={`slide-${index}`} key={uuid()}>
                {slide}
              </S.Slide>
            ))}
          </S.SliderSection>
        </S.Slider>
        <S.Button
          aria-label="previous"
          left
          onClick={() => handleArrowClick("left")}
        ></S.Button>
        <S.Button
          aria-label="next"
          right
          onClick={() => handleArrowClick("right")}
        ></S.Button>
      </S.Carousel>
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section`
  width: 100%;
  overflow: hidden;
`;

S.Carousel = styled.div<StyleProps>`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: ${(p) => p.contentDirection};
`;

S.Slider = styled.div<StyleProps>`
  display: flex;
  height: 100%;
  width: ${(p) => p.carouselWidth}%;
  overflow: hidden;
  flex-shrink: 0;
  transition: ${(p) => (p.transition ? "all 0.5s" : "none")};
  transform: translate(${(p) => p.transform}%);
`;

S.Slide = styled.div`
  width: 100%;
`;

S.SliderSection = styled.div<StyleProps>`
  flex-basis: ${(p) => p.transformPercentage}%;
  width: ${(p) => p.transformPercentage}%;
  flex-shrink: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.Button = styled.button<StyleProps>`
  position: absolute;
  align-self: center;
  width: 0;
  height: 0;
  border: none;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid ${colours.shellGuideline};
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  ${(p) =>
    p.left &&
    `
    left: 10px;
    transform: rotate(180deg);
  `}

  ${(p) => p.right && `right: 10px;`}

  &:hover {
    opacity: 0.8;
  }
`;

export default RxHeaderCarousel;

import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "utils/device";
import { CM } from "components/Typography";
import Image from "next/image";
import { Article } from "@/types/article/Article";
import { useRouter } from "next/router";

type StyleProps = {
  backgroundColour?: string;
  isSmall?: boolean;
};

type ArticleScrollProps = {
  articles: Article[];
};

const ArticleScroll: FunctionComponent<ArticleScrollProps> = (props) => {
  const router = useRouter();

  const linkHandler = async (url: string) => {
    await router.push("/edit/articles/" + url + "#from-top");
  };

  return (
    <S.ScrollContainer>
      {props.articles.map((article, index) => (
        <S.ArticleCard
          key={article.title}
          isSmall={index % 2 !== 0}
          onClick={() => linkHandler(article.url)}
        >
          <S.ArticleImage>
            <Image
              src={article.heroImage.image.url}
              layout="fill"
              objectFit="cover"
            />
          </S.ArticleImage>
          <S.CopyContainer>
            <CM>
              <b>{article.title}</b>
            </CM>
            <CM>{article.description}</CM>
          </S.CopyContainer>
        </S.ArticleCard>
      ))}
    </S.ScrollContainer>
  );
};

const S = () => {};

S.ScrollContainer = styled.ul`
  width: 100%;
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;

  @media (min-width: ${Device.desktop}) {
    margin: 4% 0 0 0;
    max-width: 1440px;
    padding: 20px 0 0 0;
    gap: 30px;
  }
`;

S.ArticleCard = styled.li<StyleProps>`
  list-style-type: none;
  flex-shrink: 0;
  margin: 0 0 0 20px;
  width: ${(p) => (p.isSmall ? "180" : "250")}px;
  background: ${(p) => p.backgroundColour};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
  }

  @media (min-width: ${Device.mobile}) {
    margin: 0 0 0 30px;
    width: ${(p) => (p.isSmall ? "200" : "300")}px;
  }
`;

S.ArticleImage = styled.div`
  position: relative;
  aspect-ratio: 1;
  width: 100%;

  @supports not (aspect-ratio: 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: "";
    }
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
`;

S.CopyContainer = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default ArticleScroll;
